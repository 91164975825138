import gql from 'graphql-tag';

export default gql`
  query smeWarehouses {
    sme_warehouses {
      id
      name
      code
      sme_id
    }
}


`;
