import type { MenuItem } from "./types";

export const menuItems: MenuItem[] = [
    {
        label: 'Tài khoản chính',
        key: 'user',
    },
    {
        label: 'Tài khoản phụ',
        key: 'sub-user',
    },
];