import styled from "styled-components";

export const PaginationWrapper = styled.div`
  .text-center {
    text-align: center;
}

.my-8 {
    margin: 2rem 0;
}

.text-muted {
    color: #6c757d;
}

.d-flex {
    display: flex;
}

.justify-content-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

.py-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.px-3 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mr-10 {
    margin-right: 2.5rem;
}

.text-primary {
    color: #ff5629;
}

.font-weight-bold {
    font-weight: bold;
}

.border-0 {
    border: none;
}

.bg-light-primary {
    background-color: transparent;
}

.mr-4 {
    margin-right: 1rem;
}

.min-width-150 {
    min-width: 150px;
}

.flex-wrp {
    flex-wrap: wrap;
}

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.mr-3 {
    margin-right: 1rem;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.25rem;
    height: 2.25rem;
    padding: 0;
    font-size: 1.25rem;
    line-height: 1;
    border-radius: 50%;
}

.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.btn-light-primary {
    color: #ff5629;
    background-color: transparent;
}

.btn-hover-primary:hover {
    color: #ff5629;
    background-color: #d6d8db;
    border-color: #d6d8db;
}

.active {
    color: #ff5629;
    background-color: #fff;
    border-color: #ff5629;
}

.icon-xs {
    font-size: 0.75rem;
}
`;