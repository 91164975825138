import React from "react";
import Layout from "./components/Layout";
import { Navigate, Route, Routes } from 'react-router-dom';
import UserSetting from "./UserSetting";

const SettingPage = () => {
    return (
        <Layout>
            <Routes>
                <Route path={'/'} element={<Navigate to="/settings/user" />} />
            </Routes>
        </Layout>
    )
};

export default SettingPage;