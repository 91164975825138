
export default function setupAxios(axios) {
  axios.interceptors.request.use(
    async config => {
      config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

      return config;
    },
    err => Promise.reject(err)
  );
}
